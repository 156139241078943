import React, { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useAnimations, useProgress, Environment } from '@react-three/drei'

const Model = ({ isTalking, talkAnimaton, onLoad, onProgress, isThinking, isListening, visemeData }) => {

  // LOADING DATA
  // const { active, progress } = useProgress();
  // useEffect(() => {
  //   if (!active) {
  //     onLoad();
  //   }
  //   onProgress(progress);
  // }, [active, onLoad, onProgress, progress]);


  const [scale, setScale] = useState(0.8)
  const [positionY, setPositionY] = useState(-1.5)

  const group = useRef()
  const gltf = useGLTF('https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/biju_anims3.glb')

  const { actions } = useAnimations(gltf.animations, group)
  // console.log(actions)
  // Use useRef for mutable references that persist across renders
  const previousAction = useRef(null);
  const previousHeadAction = useRef(null);

  // useEffect(() => {
  //   if (!actions) return; // Check if actions are loaded

  //   // Initialize actions on the first render
  //   if (!previousAction.current || !previousHeadAction.current) {
  //     previousAction.current = actions['Idle1'];
  //     previousHeadAction.current = actions['Idle1_Head'];
  //     actions['Idle1_Head'].play();
  //     actions['Idle1'].play();
  //     return;
  //   }

  //   // Access the current value using .current
  //   let newAction, newHeadAction;

  //   // Create arrays for idle and talking animations
  //   const idleAnimations = ['Idle1', 'Idle2', 'Idle3'];
  //   const talkingAnimations = ['Talking1', 'Talking2', 'Talking3'];

  //   if (isListening) {
  //     newAction = actions['Listening'];
  //     newHeadAction = actions['Listening_Head'];
  //   } else if (isThinking && !isTalking) {
  //     newAction = actions['Thinking'];
  //     newHeadAction = actions['Thinking_Head'];
  //   } else if (isTalking && !isThinking) {
  //     // Select a random talking animation
  //     const randomTalkingAnimation = talkingAnimations[Math.floor(Math.random() * talkingAnimations.length)];
  //     newAction = actions[randomTalkingAnimation];
  //     newHeadAction = actions[`${randomTalkingAnimation}_Head`];
  //   } else {
  //     // Select a random idle animation
  //     const randomIdleAnimation = idleAnimations[Math.floor(Math.random() * idleAnimations.length)];

  //     newAction = actions[randomIdleAnimation];
  //     newHeadAction = actions[`${randomIdleAnimation}_Head`];
  //   }

  //   // Crossfade to the new animations
  //   crossFade(previousAction.current, newAction, 0.5);
  //   crossFade(previousHeadAction.current, newHeadAction, 0.5);

  //   // Update the previous actions
  //   previousAction.current = newAction;
  //   previousHeadAction.current = newHeadAction;
  // }, [actions, isThinking, isTalking, isListening]);


  useEffect(() => {
    if (!actions) return; // Check if actions are loaded

    // Initialize actions on the first render
    if (!previousAction.current) {
      previousAction.current = actions['Idle1'];
      actions['Idle1'].play();
      return;
    }

    // Access the current value using .current
    let newAction;

    // Create arrays for idle and talking animations
    const idleAnimations = ['Idle1', 'Idle2', 'Idle3'];
    const talkingAnimations = ['Talking1', 'Talking2', 'Talking3'];

    if (isListening) {
      newAction = actions['Listening'];
    } else if (isThinking && !isTalking) {
      newAction = actions['Thinking'];
    } else if (isTalking && !isThinking) {
      // Select a random talking animation
      const randomTalkingAnimation = talkingAnimations[Math.floor(Math.random() * talkingAnimations.length)];
      newAction = actions[randomTalkingAnimation];
    } else {
      // Select a random idle animation
      const randomIdleAnimation = idleAnimations[Math.floor(Math.random() * idleAnimations.length)];

      newAction = actions[randomIdleAnimation];
    }

    // Crossfade to the new animations
    crossFade(previousAction.current, newAction, 0.5);

    // Update the previous actions
    previousAction.current = newAction;
  }, [actions, isThinking, isTalking, isListening]);



  const crossFade = (fromAction, toAction, duration) => {
    // Ensure both actions exist
    if (fromAction && toAction) {
      // Crossfade from the current action to the new action
      fromAction.crossFadeTo(toAction, duration, true);
      toAction.play();

      setTimeout(() => {
        fromAction.stop();
      }, duration * 1000);
    }
  };


  const mesh = gltf.scene.children[0].children[2].children[0]
  // mesh.morphTargetInfluences[1] = 0.2


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [speechMarks, setSpeechMarks] = useState([]);

  useEffect(() => {
    setSpeechMarks(visemeData)
  }, [visemeData])



  const phonemeMapping = {
    'a': 'Viseme_A',
    'e': 'Viseme_E',
    'o': 'Viseme_O',
    'u': 'Viseme_U',
    'aa': 'phoneme_aa',
    '{': 'phoneme_ae_ax_ah',
    'A:': 'phoneme_ao',
    'aU': 'phoneme_aw',
    'aI': 'phoneme_ay',
    'd': 'phoneme_d_t_n',
    '3:': 'phoneme_er',
    'eI': 'phoneme_ey_eh_uh',
    'f': 'phoneme_f_v',
    'h': 'phoneme_h',
    'k': 'phoneme_k_g_ng',
    'l': 'phoneme_l',
    '@U': 'phoneme_ow',
    'OI': 'phoneme_oy',
    'p': 'phoneme_p_b_m',
    'r\\': 'phoneme_r',
    's': 'phoneme_s_z',
    'S': 'phoneme_sh_ch_jh_zh',
    'T': 'phoneme_th_dh',
    'w': 'phoneme_w_uw',
    'i': 'phoneme_y_iy_ih_ix',
    'b': 'phoneme_p_b_m',
    'dZ': 'phoneme_sh_ch_jh_zh',
    'D': 'phoneme_th_dh',
    'g': 'phoneme_k_g_ng',
    'j': 'phoneme_y_iy_ih_ix',
    'l=': 'phoneme_l',
    'm': 'phoneme_p_b_m',
    'm=': 'phoneme_p_b_m',
    'n': 'phoneme_d_t_n',
    'n=': 'phoneme_d_t_n',
    'N': 'phoneme_k_g_ng',
    'r': 'phoneme_r',
    't': 'phoneme_d_t_n',
    'tS': 'phoneme_sh_ch_jh_zh',
    'v': 'phoneme_f_v',
    'z': 'phoneme_s_z',
    'Z': 'phoneme_sh_ch_jh_zh',
    '@': 'phoneme_ey_eh_uh',
    'E': 'phoneme_ey_eh_uh',
    'I': 'phoneme_y_iy_ih_ix',
    'I@': 'phoneme_y_iy_ih_ix',
    'Q': 'phoneme_w_uw',
    'U': 'phoneme_w_uw',
    'U@': 'phoneme_w_uw',
    'V': 'phoneme_f_v',
    '"': 'phoneme_p_b_m',
    '%': 'phoneme_aa',
    '.': 'phoneme_ao',
    'sil': 'Sil'
  };

  const morphTargetIndices = {
    "mouthOpen": 0,
    "mouthSmile": 1,
    "Eyes_Close": 2,
    "Viseme_A": 3,
    "Viseme_E": 4,
    "Viseme_I": 5,
    "Viseme_O": 6,
    "Viseme_U": 7,
    "Thinking": 8,
    "Listening": 9,
    "Sil": 10,
    "idle": 11,
    "phoneme_aa": 12,
    "phoneme_ae_ax_ah": 13,
    "phoneme_ao": 14,
    "phoneme_aw": 15,
    "phoneme_ay": 16,
    "phoneme_d_t_n": 17,
    "phoneme_er": 18,
    "phoneme_ey_eh_uh": 19,
    "phoneme_f_v": 20,
    "phoneme_h": 21,
    "phoneme_k_g_ng": 22,
    "phoneme_l": 23,
    "phoneme_ow": 24,
    "phoneme_oy": 25,
    "phoneme_p_b_m": 26,
    "phoneme_r": 27,
    "phoneme_s_z": 28,
    "phoneme_sh_ch_jh_zh": 29,
    "phoneme_th_dh": 30,
    "phoneme_w_uw": 31,
    "phoneme_y_iy_ih_ix": 32
  };


  const [isRunning, setIsRunning] = useState(false)
  const [startTime, setStartTime] = useState(null);

  const [lastMark, setLastMark] = useState(null);

  function cubicEase(t) {
    return t * t * (3 - 2 * t);
  }

  function smoothStep(current, target, smoothingFactor) {
    return current + (target - current) * cubicEase(smoothingFactor / 1.3);
  }

  let prevTarget = null;
  let prevInfluence = 0;
  let index = null;

  function blendBetweenMorphTargets(mesh, currentViseme, fadeInDuration, fadeOutDuration) {
    const target = phonemeMapping[currentViseme];
    if (target !== undefined) {
      const index = morphTargetIndices[target];

      // Gradually increase the influence of the current target
      mesh.morphTargetInfluences[index] = smoothStep(
        mesh.morphTargetInfluences[index],
        1,
        0.7
      );

      // Gradually reduce the influence of all other targets
      for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
        if (i !== index) {
          mesh.morphTargetInfluences[i] = smoothStep(
            mesh.morphTargetInfluences[i],
            0,
            0.7
          );
        }
      }

      // Use a Promise to ensure that the influence reduction occurs immediately after the specified durations
      const waitForAnimation = () => {
        return new Promise(resolve => {
          setTimeout(resolve, fadeInDuration + fadeOutDuration);
        });
      };

      waitForAnimation().then(() => {
        // Gradually reduce the influence of the current target after fadeInDuration
        mesh.morphTargetInfluences[index] = smoothStep(
          mesh.morphTargetInfluences[index],
          0,
          0.7
        );
      });
    }
  }

  function binarySearch(array, targetTime) {
    let low = 0;
    let high = array.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const midTime = array[mid].time;

      if (midTime <= targetTime) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    // The correct viseme is either at index 'low' or 'high'
    const lastIndex = Math.max(0, high);

    return array[lastIndex];
  }


  useFrame(() => {
    if (isRunning) {
      const elapsedTime = Math.round(performance.now() - startTime);
      const currentMark = binarySearch(speechMarks, elapsedTime);

      if (currentMark && currentMark.type === 'viseme' && currentMark !== lastMark) {
        const target = phonemeMapping[currentMark.value];

        // console.log(currentMark.value, target);

        if (target !== undefined) {
          // Smoothly transition to the current viseme
          blendBetweenMorphTargets(mesh, currentMark.value, 2000, 2000);

          // If there was a previous target, smoothly reduce its influence
          if (prevTarget !== null) {
            mesh.morphTargetInfluences[prevTarget] = smoothStep(
              prevInfluence,
              0,
              0.5
            );
          }

          // Gradually increase the influence of the current target
          mesh.morphTargetInfluences[index] = smoothStep(
            mesh.morphTargetInfluences[index],
            1,
            0.85
          );

          // Update the previous target and its influence
          prevTarget = index;
          prevInfluence = mesh.morphTargetInfluences[index];
        }

        setLastMark(currentMark);
      }

      // Gradually reduce the influence of morph targets that are not the current or previous target
      for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
        if (
          mesh.morphTargetInfluences[i] !== 0 &&
          i !== prevTarget &&
          i !== index
        ) {
          mesh.morphTargetInfluences[i] = smoothStep(
            mesh.morphTargetInfluences[i],
            0,
            0.125
          );
        }
      }
    }
  });


  // Function to start the animation
  const startTalkAnimation = () => {
    setIsRunning(true);
    setStartTime(performance.now());
  }

  // Function to stop the animation
  const stopTalkAnimation = () => setIsRunning(false)

  useEffect(() => {
    if (isTalking) {
      startTalkAnimation()
    } else {
      stopTalkAnimation()
    }
  }, [isTalking]);


  // UI
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setScale(0.55)
        setPositionY(-1.1)
      }
      else if (window.innerWidth < 900) {
        setScale(0.8)
        setPositionY(-1.5)
      }
      else if (window.innerWidth < 1100) {
        setScale(0.95)
        setPositionY(-1.68)
      }
      else if (window.innerWidth < 1300) {
        setScale(1.1)
        setPositionY(-1.88)
      }
      else if (window.innerWidth < 1500) {
        setScale(1.2)
        setPositionY(-2)
      }
      else if (window.innerWidth < 1700) {
        setScale(1.3)
        setPositionY(-2.2)
      }
      else {
        setScale(1.4)
        setPositionY(-2.325)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <primitive ref={group} position={[0, positionY, 0]} scale={scale} object={gltf.scene} />
}

const BotCanvas = ({ isTalking, talkAnimaton, onLoad, onProgress, isThinking, isListening, visemeData }) => {
  return (
    <div className="bot-cont">
      <Canvas shadows camera={{ position: [0.175, 0, 1], fov: 40 }}>
        <ambientLight intensity={0.75} />
        <directionalLight position={[3.3, 1.0, 4.4]} intensity={4.5} />
        <Model isTalking={isTalking} isThinking={isThinking} isListening={isListening} visemeData={visemeData} />
      </Canvas>
    </div>
  )
}

export default BotCanvas