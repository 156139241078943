const ServerError = [
  {
    "time": 6,
    "type": "word",
    "start": 0,
    "end": 7,
    "value": "Welcome"
  },
  {
    "time": 6,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 76,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 127,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 190,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 244,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 297,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 335,
    "type": "word",
    "start": 8,
    "end": 10,
    "value": "to"
  },
  {
    "time": 335,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 381,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 430,
    "type": "word",
    "start": 11,
    "end": 14,
    "value": "the"
  },
  {
    "time": 430,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 489,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 528,
    "type": "word",
    "start": 15,
    "end": 20,
    "value": "Foyer"
  },
  {
    "time": 528,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 640,
    "type": "viseme",
    "value": "O"
  },
  {
    "time": 815,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 1069,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 1264,
    "type": "word",
    "start": 22,
    "end": 23,
    "value": "a"
  },
  {
    "time": 1264,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 1325,
    "type": "word",
    "start": 24,
    "end": 31,
    "value": "central"
  },
  {
    "time": 1325,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 1450,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 1521,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1562,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1639,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 1670,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 1702,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1738,
    "type": "word",
    "start": 32,
    "end": 37,
    "value": "space"
  },
  {
    "time": 1738,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 1860,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 1913,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 2066,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 2167,
    "type": "word",
    "start": 38,
    "end": 42,
    "value": "with"
  },
  {
    "time": 2167,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 2235,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2263,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 2309,
    "type": "word",
    "start": 43,
    "end": 44,
    "value": "a"
  },
  {
    "time": 2309,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 2348,
    "type": "word",
    "start": 45,
    "end": 54,
    "value": "luxurious"
  },
  {
    "time": 2348,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2431,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 2495,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 2559,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 2655,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 2741,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 2817,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2892,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 2936,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 3033,
    "type": "word",
    "start": 55,
    "end": 63,
    "value": "stairway"
  },
  {
    "time": 3033,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 3115,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3172,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 3242,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 3295,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 3412,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 3515,
    "type": "word",
    "start": 64,
    "end": 74,
    "value": "connecting"
  },
  {
    "time": 3515,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 3599,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 3643,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3708,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 3779,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 3853,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3910,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 3949,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 4021,
    "type": "word",
    "start": 75,
    "end": 78,
    "value": "you"
  },
  {
    "time": 4021,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 4108,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 4164,
    "type": "word",
    "start": 79,
    "end": 81,
    "value": "to"
  },
  {
    "time": 4164,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4245,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 4355,
    "type": "word",
    "start": 82,
    "end": 85,
    "value": "all"
  },
  {
    "time": 4355,
    "type": "viseme",
    "value": "O"
  },
  {
    "time": 4453,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4526,
    "type": "word",
    "start": 86,
    "end": 91,
    "value": "rooms"
  },
  {
    "time": 4526,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 4619,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 4721,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 4806,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 4884,
    "type": "word",
    "start": 92,
    "end": 94,
    "value": "in"
  },
  {
    "time": 4884,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 4916,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4956,
    "type": "word",
    "start": 95,
    "end": 98,
    "value": "the"
  },
  {
    "time": 4956,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 4980,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5021,
    "type": "word",
    "start": 99,
    "end": 110,
    "value": "Residential"
  },
  {
    "time": 5021,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 5153,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 5192,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 5271,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5311,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5362,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 5421,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5475,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 5536,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5676,
    "type": "word",
    "start": 111,
    "end": 115,
    "value": "Area"
  },
  {
    "time": 5676,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 5760,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 5846,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 5924,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 6001,
    "type": "word",
    "start": 116,
    "end": 119,
    "value": "and"
  },
  {
    "time": 6001,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 6048,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6078,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6120,
    "type": "word",
    "start": 120,
    "end": 129,
    "value": "featuring"
  },
  {
    "time": 6120,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 6185,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 6265,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 6367,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 6433,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 6471,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 6543,
    "type": "word",
    "start": 130,
    "end": 132,
    "value": "an"
  },
  {
    "time": 6543,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 6569,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6679,
    "type": "word",
    "start": 133,
    "end": 139,
    "value": "atrium"
  },
  {
    "time": 6679,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 6848,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6958,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 7021,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 7079,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 7115,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 7186,
    "type": "word",
    "start": 140,
    "end": 147,
    "value": "ceiling"
  },
  {
    "time": 7186,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 7312,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 7387,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 7459,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 7511,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 7579,
    "type": "word",
    "start": 148,
    "end": 152,
    "value": "that"
  },
  {
    "time": 7579,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 7615,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 7682,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 7730,
    "type": "word",
    "start": 153,
    "end": 159,
    "value": "bathes"
  },
  {
    "time": 7730,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 7789,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 7911,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 7959,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 8041,
    "type": "word",
    "start": 160,
    "end": 163,
    "value": "the"
  },
  {
    "time": 8041,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 8100,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8186,
    "type": "word",
    "start": 164,
    "end": 168,
    "value": "area"
  },
  {
    "time": 8186,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 8253,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 8397,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8472,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 8532,
    "type": "word",
    "start": 169,
    "end": 171,
    "value": "in"
  },
  {
    "time": 8532,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8620,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 8665,
    "type": "word",
    "start": 172,
    "end": 179,
    "value": "natural"
  },
  {
    "time": 8665,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 8745,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 8848,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 8974,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 9029,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 9056,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 9094,
    "type": "word",
    "start": 180,
    "end": 185,
    "value": "light"
  },
  {
    "time": 9094,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 9189,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 9347,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 9514,
    "type": "viseme",
    "value": "sil"
  }
]

export default ServerError;