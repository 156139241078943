import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

function FunFactLoader() {
  const funFacts = [
    "Butati, a village in Rajasthan was established around 1600AD, In the control of Rajputs.",
    "Butati has the famous Butati Dham is also known as 'Chaturdas Ji Maharaj's temple.",
    "In this temple, a person suffering from paralysis becomes completely healthy in just seven parikrama.",
    "Ekadashi of Shukla Paksha every month is always a special day in Butati Dham.",
    "Abhushan mahal's purpose for construction is for Residential and hospitality for visitors of Butati Dham.",
  ];

  const [currentFact, setCurrentFact] = useState(funFacts[0]);

  // Function to select a random fact
  const selectRandomFact = () => {
    const randomIndex = Math.floor(Math.random() * funFacts.length);
    const newFact = funFacts[randomIndex];
    if (newFact !== currentFact) {
      setCurrentFact(newFact);
    } else {
      selectRandomFact(); // If it's the same fact, recursively call again
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      selectRandomFact();
    }, 15000);

    return () => clearInterval(timer); // Clear the timer on unmount
  }, [currentFact]);

  return (
    <motion.div
      key={currentFact} // Use the currentFact as the key
      className="fun-fact-container"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="fun-fact-text ff-l fs-xs">{currentFact}</div>
    </motion.div>
  );
}

export default FunFactLoader;
