const CourtyardGreet = [
  {
    "time": 6,
    "type": "word",
    "start": 0,
    "end": 4,
    "value": "Step"
  },
  {
    "time": 6,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 127,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 190,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 269,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 340,
    "type": "word",
    "start": 5,
    "end": 9,
    "value": "into"
  },
  {
    "time": 340,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 378,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 433,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 495,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 543,
    "type": "word",
    "start": 10,
    "end": 13,
    "value": "the"
  },
  {
    "time": 543,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 610,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 644,
    "type": "word",
    "start": 14,
    "end": 25,
    "value": "magnificent"
  },
  {
    "time": 644,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 711,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 801,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 844,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 900,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 961,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 1035,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 1083,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 1191,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1300,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1330,
    "type": "word",
    "start": 26,
    "end": 35,
    "value": "courtyard"
  },
  {
    "time": 1330,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 1445,
    "type": "viseme",
    "value": "O"
  },
  {
    "time": 1517,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 1573,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1608,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 1659,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 1739,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 1795,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1837,
    "type": "word",
    "start": 36,
    "end": 38,
    "value": "of"
  },
  {
    "time": 1837,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 1869,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 1912,
    "type": "word",
    "start": 39,
    "end": 42,
    "value": "our"
  },
  {
    "time": 1912,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 1969,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 2024,
    "type": "word",
    "start": 43,
    "end": 54,
    "value": "hospitality"
  },
  {
    "time": 2024,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 2128,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 2210,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 2307,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 2363,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2395,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2507,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 2585,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2695,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 2725,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2772,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2858,
    "type": "word",
    "start": 55,
    "end": 59,
    "value": "area"
  },
  {
    "time": 2858,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 2924,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 3063,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 3156,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 3333,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 3527,
    "type": "word",
    "start": 61,
    "end": 69,
    "value": "inviting"
  },
  {
    "time": 3527,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 3596,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3670,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 3720,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 3840,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3877,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 3919,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 3991,
    "type": "word",
    "start": 70,
    "end": 73,
    "value": "you"
  },
  {
    "time": 3991,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 4064,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 4129,
    "type": "word",
    "start": 74,
    "end": 76,
    "value": "to"
  },
  {
    "time": 4129,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4220,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 4293,
    "type": "word",
    "start": 77,
    "end": 84,
    "value": "immerse"
  },
  {
    "time": 4293,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 4336,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 4414,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 4509,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 4576,
    "type": "word",
    "start": 85,
    "end": 93,
    "value": "yourself"
  },
  {
    "time": 4576,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 4615,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 4693,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 4789,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 4833,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4919,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 4991,
    "type": "word",
    "start": 94,
    "end": 96,
    "value": "in"
  },
  {
    "time": 4991,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 5033,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5080,
    "type": "word",
    "start": 97,
    "end": 98,
    "value": "a"
  },
  {
    "time": 5080,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5120,
    "type": "word",
    "start": 99,
    "end": 104,
    "value": "world"
  },
  {
    "time": 5120,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 5232,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 5309,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5380,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5423,
    "type": "word",
    "start": 105,
    "end": 107,
    "value": "of"
  },
  {
    "time": 5423,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5471,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 5533,
    "type": "word",
    "start": 108,
    "end": 116,
    "value": "opulence"
  },
  {
    "time": 5533,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 5644,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 5704,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 5745,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5785,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5848,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5882,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5936,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 6007,
    "type": "word",
    "start": 117,
    "end": 120,
    "value": "and"
  },
  {
    "time": 6007,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 6067,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6107,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6150,
    "type": "word",
    "start": 121,
    "end": 128,
    "value": "comfort"
  },
  {
    "time": 6150,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 6257,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 6300,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 6345,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 6416,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 6553,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6698,
    "type": "viseme",
    "value": "sil"
  }
]

export default CourtyardGreet