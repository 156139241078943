import * as React from 'react';
import Box from '@mui/material/Box';
import { useSpring, animated } from '@react-spring/web';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669 .281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function ArrowRightIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M10 17l5-5-5-5v10z" />
    </SvgIcon>
  );
}

function ArrowDownIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17 10l-5 5-5-5h10z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = React.forwardRef(
  (props, ref) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
  ),
);

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    marginLeft: 'auto',   // Move the icon to the right
    marginRight: 5,       // Add some margin for spacing
  },
  [`& .${treeItemClasses.content}`]: {
    flexDirection: 'row-reverse',  // Reverse the direction of content
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed #c4c4c4`,
  },
}));

export default function CustomizedTreeView({ triggerUnityFunction, switchBuild, isLoading, build }) {

  return (
    <div className='navigation-ui'>
      <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: 300 }}>
        <TreeView
          aria-label="customized"
          defaultExpanded={['1']}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          // defaultEndIcon={<CloseSquare />}
          // defaultEndIcon={<LocationIcon />}
          sx={{ overflowX: 'hidden' }}

        >
          {/* <div className='sceneNameCont'> */}
          {/* <div className='sceneNameCont' onClick={() => triggerUnityFunction(0)}> */}
          <div className=''>
            {/* <StyledTreeItem nodeId="1" label="EXTERIOR" onClick={() => triggerUnityFunction(0)}>
            </StyledTreeItem> */}
            <StyledTreeItem nodeId="1" label="EXTERIOR" onClick={() => switchBuild("Exterior")} disabled={isLoading}>
            </StyledTreeItem>
          </div>
          {/* </div> */}

          {/* <div className='sceneNameCont' onClick={() => triggerUnityFunction(1)}> */}
          {/* <div className='sceneNameCont'> */}

          {
            build !== "Foyer" ?
              <StyledTreeItem nodeId="2" label="RESIDENTIAL" onClick={() => switchBuild("Foyer")} disabled={isLoading}>
              </StyledTreeItem>
              :
              <StyledTreeItem nodeId="2" label="RESIDENTIAL" disabled={isLoading}>
                <StyledTreeItem nodeId="4" label="FOYER" onClick={() => triggerUnityFunction(0, "Foyer")} />
                <StyledTreeItem nodeId="5" label="BEDROOM 1" onClick={() => triggerUnityFunction(1, "Bedroom_1")} />
                <StyledTreeItem nodeId="6" label="BEDROOM 2" onClick={() => triggerUnityFunction(2, "Bedroom_2")} />
                <StyledTreeItem nodeId="7" label="BEDROOM 3" onClick={() => triggerUnityFunction(3, "Bedroom_3")} />
                <StyledTreeItem nodeId="8" label="BEDROOM 4" onClick={() => triggerUnityFunction(1, "Bedroom_4")} />
                <StyledTreeItem nodeId="9" label="BEDROOM 5" onClick={() => triggerUnityFunction(4, "Bedroom_5")} />
                <StyledTreeItem nodeId="10" label="BEDROOM 6" onClick={() => triggerUnityFunction(3, "Bedroom_6")} />
                <StyledTreeItem nodeId="11" label="BEDROOM 7" onClick={() => triggerUnityFunction(2, "Bedroom_7")} />
                <StyledTreeItem nodeId="12" label="FORMAL LIVING" onClick={() => triggerUnityFunction(5, "Dinning_Room")} />
                <StyledTreeItem nodeId="13" label="OFFICE SPACE" onClick={() => triggerUnityFunction(6, "Office_Space")} />
              </StyledTreeItem>
          }

          {
            build !== "Courtyard" ?
              <StyledTreeItem nodeId="3" label="DHARMASHALA" onClick={() => switchBuild("Courtyard")} disabled={isLoading}>
              </StyledTreeItem>
              :
              <StyledTreeItem nodeId="3" label="DHARMASHALA" disabled={isLoading}>
                <StyledTreeItem nodeId="14" label="COURTYARD" onClick={() => triggerUnityFunction(0, "Courtyard")} />
                <StyledTreeItem nodeId="15" label="WORKSTATIONS" onClick={() => triggerUnityFunction(1, "Workstations")} />
                <StyledTreeItem nodeId="16" label="SAINTS ROOM FEMALE" onClick={() => triggerUnityFunction(2, "Saints Room Female")} />
                <StyledTreeItem nodeId="17" label="SAINTS ROOM MALE" onClick={() => triggerUnityFunction(3, "Saint Room Male")} />
                <StyledTreeItem nodeId="18" label="BANQUET HALL" onClick={() => triggerUnityFunction(4, "BanquetHall")} />
                <StyledTreeItem nodeId="19" label="DINING HALL" onClick={() => triggerUnityFunction(5, "Dining Hall")} />
                <StyledTreeItem nodeId="20" label="TYPICAL ROOM" onClick={() => triggerUnityFunction(6, "Typical Room")} />
                <StyledTreeItem nodeId="21" label="OUTDOOR RESTAURANT" onClick={() => triggerUnityFunction(7, "Outdoor_Restaurant")} />
                <StyledTreeItem nodeId="22" label="DORMITORY FEMALE" onClick={() => triggerUnityFunction(8, "Dormitory")} />
              </StyledTreeItem>
          }

        </TreeView>
      </Box>
    </div>
  );
}
