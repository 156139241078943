import React, { useEffect, useRef } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";

const MobileBuild = ({ reloadBuild, unloadExterior, setLoadingProgression, setIsLoaded, setUnloadExterior, setReadyToLoad, sendMessageRef, sceneToLoad, isMobileDevice, setBuild, setReloadBuild, setCurrentLocation }) => {

  let scene = localStorage.getItem("sceneToLoad") || "Exterior"
  setBuild(scene)

  let unityContext, unityContextMobile

  if (scene === "Exterior") {
    unityContext = {
      loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.loader.js",
      dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.data.br",
      frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.framework.js.br",
      codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.wasm.br",
    };

    unityContextMobile = {
      loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_Mobile_27_02/Build/Exterior_Mobile_27_02.loader.js",
      dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_Mobile_27_02/Build/Exterior_Mobile_27_02.data.br",
      frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_Mobile_27_02/Build/Exterior_Mobile_27_02.framework.js.br",
      codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_Mobile_27_02/Build/Exterior_Mobile_27_02.wasm.br",
    };
  }
  else if (scene === "Foyer") {
    unityContext = {
      loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/INTERIOR_12_02/Build/INTERIOR_12_02.loader.js",
      dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/INTERIOR_12_02/Build/INTERIOR_12_02.data.br",
      frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/INTERIOR_12_02/Build/INTERIOR_12_02.framework.js.br",
      codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/INTERIOR_12_02/Build/INTERIOR_12_02.wasm.br",
    };

    unityContextMobile = {
      loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Residential_Mobile_interior_27_02/Build/Residential_Mobile_interior_27_02.loader.js",
      dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Residential_Mobile_interior_27_02/Build/Residential_Mobile_interior_27_02.data.br",
      frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Residential_Mobile_interior_27_02/Build/Residential_Mobile_interior_27_02.framework.js.br",
      codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Residential_Mobile_interior_27_02/Build/Residential_Mobile_interior_27_02.wasm.br",
    };
  }
  else {
    unityContext = {
      loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_12_02/Build/dharamsala_12_02.loader.js",
      dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_12_02/Build/dharamsala_12_02.data.br",
      frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_12_02/Build/dharamsala_12_02.framework.js.br",
      codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_12_02/Build/dharamsala_12_02.wasm.br",
    };

    unityContextMobile = {
      loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_Mobile_23_02/Build/Dharamsala_Mobile_23_02.loader.js",
      dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_Mobile_23_02/Build/Dharamsala_Mobile_23_02.data.br",
      frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_Mobile_23_02/Build/Dharamsala_Mobile_23_02.framework.js.br",
      codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Dharamsala_Mobile_23_02/Build/Dharamsala_Mobile_23_02.wasm.br",
    };
  }

  const { unityProvider, loadingProgression, isLoaded, unload, UNSAFE__unityInstance, sendMessage } = useUnityContext(isMobileDevice ? unityContextMobile : unityContext);

  const isProgrammaticRefresh = useRef(false);

  const handleBeforeUnload = () => {
    if (!isProgrammaticRefresh.current) {
      localStorage.clear();
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const unloadAndSetReadyToLoad = async () => {
      if (reloadBuild) {
        await window.unityInstance.Quit()
        isProgrammaticRefresh.current = true;
        localStorage.setItem("sceneToLoad", sceneToLoad.current);
        localStorage.setItem("sceneLoadingVideo", sceneToLoad.current === "Exterior" ? "Exterior" : "Foyer");
        setTimeout(() => {
          window.location.reload(true);
        }, 100);
      }
    };

    unloadAndSetReadyToLoad();
  }, [reloadBuild, unloadExterior, unload, setReadyToLoad, setUnloadExterior, sceneToLoad]);

  useEffect(() => {
    setLoadingProgression(loadingProgression)
  }, [loadingProgression, setLoadingProgression])


  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(isLoaded)
      if (localStorage.getItem("sceneToLoad") === "Courtyard"){
        setCurrentLocation("Courtyard")
      }
    }
  }, [isLoaded, setIsLoaded])


  useEffect(() => {
    if (UNSAFE__unityInstance) {
      window.unityInstance = UNSAFE__unityInstance;

      return () => {
        // Clean up the global reference when unmounting or unloading the build
        window.unityInstance = null;
      };
    }
  }, [UNSAFE__unityInstance]);


  useEffect(() => {
    sendMessageRef.current = sendMessage
  }, [sendMessage, sendMessageRef])


  return (
    <Unity unityProvider={unityProvider} />
  );
}

export default MobileBuild;
