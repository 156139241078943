import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { BeatLoader } from 'react-spinners'

import ServerError from "./VisemesData/ServerError";
import ExteriorGreet from "./VisemesData/ExteriorGreet";
import ResidentialGreet from "./VisemesData/ResidentialGreet";


import { IoMdMic } from 'react-icons/io'
import CourtyardGreet from "./VisemesData/CourtyardGreet";

const sampleRate = 16000;

const getMediaStream = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: "default",
        sampleRate: sampleRate,
        sampleSize: 16,
        channelCount: 1,
      },
      video: false,
    });
    return stream;
  } catch (error) {
    // console.error("Error accessing media stream:", error);
  }
};

const AudioToText = (props) => {
  const {
    setIsListening,
    isTalking,
    setIsTalking,
    setTalkAnimation,
    startClicked,
    setIsThinking,
    currentLocation,
    shouldPlayCommonAreaMsg,
    setShouldPlayCommonAreaMsg,
    stopAudioWhileSwitching,
    setStopAudioWhileSwitching,
    setVisemeData,
    language,
    isMobileDevice
  } = props
  const [previousLocation, setPreviousLocation] = useState(null)

  const [connection, setConnection] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [currentRecognition, setCurrentRecognition] = useState("");

  const processorRef = useRef();
  const audioContextRef = useRef();
  const audioInputRef = useRef();

  const [buttonText, setButtonText] = useState("Tap to speak")
  // const [playbackConsent, setPlaybackConsent] = useState(false)
  // const [playbackIOSText, setplaybaclIOSText] = useState("TEXT")

  const [startingToRecord, setStartingToRecord] = useState(false)
  const [responseReady, setResponseReady] = useState(true)

  const recordingStoppedByTimerRef = useRef(false);
  const [shouldPlayCourtyardMsg, setShouldPlayCourtyardMsg] = useState(true)


  useEffect(() => {
    if (isRecording) {
      setIsListening(true)
    }
    else {
      setIsListening(false)
    }
  }, [isRecording])



  const url = "https://api.butati.xrvizion.com"
  // const url = "http://localhost:5000"

  //AUDIO
  const audioRef = useRef(new Audio());
  const audioRefAlt = useRef(new Audio());
  const audioRefAlt2 = useRef(new Audio());


  const shouldErrorMsgPlay = useRef(true)

  const shouldRecordAgain = useRef(false);


  let playPromise
  const stopAudio = async () => {
    if (startClicked) {
      playPromise = audioRef.current.play();
    }
    if (playPromise !== undefined) {
      // console.log("playPromise", playPromise)
      try {
        await playPromise; // Wait for the play() Promise to settle
        audioRef.current.pause();
        // console.warn(playPromise)
        setIsTalking(false)
        setIsThinking(false)
        // console.log("should to record", shouldRecordAgain.current)

        if (shouldRecordAgain.current) {
          startRecordingAgain()
        }
      } catch (error) {
        // console.log('An error occurred:', error);
      }
    }
  };

  useEffect(() => {
    if (startClicked) {
      playPromise = audioRef.current.play();
    }
  }, [startClicked])

  useEffect(() => {
    const stopAudioSwitching = async () => {
      await stopAudio()
      setCurrentRecognition("")
      setStopAudioWhileSwitching(false)
    }

    if (stopAudioWhileSwitching) stopAudioSwitching()
  }, [stopAudioWhileSwitching])



  const startRecordingAgain = () => {
    setTimeout(() => {
      // console.warn("STARTing again")
      const recButton = document.getElementsByClassName('ui-rec-btn')[0]
      recButton.click()
    }, 1000);
  }



  // ANIMATIONS
  useEffect(() => {
    const handlePlay = () => {
      if (isTalking) {
        // Start playing a random talking animation
        const animations = ['Talking_1', 'Talking_2', 'Talking_3'];
        const animation = animations[Math.floor(Math.random() * animations.length)];

        // console.log("PLAYING", animation)
        // Use your method for playing the animation here
        // playAnimation(animation);
        setTalkAnimation(animation)
      }
    };
    audioRef.current.addEventListener('play', handlePlay);

    // Add event listener for the ended event
    const handleEnded = () => {
      setIsTalking(false)
      // console.log("should to record", shouldRecordAgain.current)
      if (shouldRecordAgain.current) {
        startRecordingAgain()
      }
    };
    audioRef.current.addEventListener('ended', handleEnded);

    // Remove event listeners when component is unmounted
    return () => {
      audioRef.current.removeEventListener('play', handlePlay);
      audioRef.current.removeEventListener('ended', handleEnded);
    };
  }, [isTalking, setIsTalking, setTalkAnimation]);


  // SOCKET IO CONNECTION AND MESSAGES
  useEffect(() => {
    // console.log("Connecting to web socket...")
    const socket = io.connect(url);

    socket.on("connect", () => {
      // console.log("connected", socket.id);
      setConnection(socket);
    });

    socket.on("completedResponse", (data) => {
      // console.log(data)
      setVisemeData(data.visemes)
      setIsTalking(true)
      setResponseReady(true)
      setIsThinking(false)
      shouldErrorMsgPlay.current = false
      // console.log("recording started, errormsg false", shouldErrorMsgPlay.current)
      // Check if the audio context is already closed
      if (audioContextRef.current.state !== 'closed') {
        // Close the audio context before starting a new playback
        audioContextRef.current.close();
      }

      // Create a new audio context for the next playback
      audioContextRef.current = new window.AudioContext();

      // Set the audio session type to 'playback' if supported
      if (navigator.audioSession) {
        // setPlaybackConsent("PLAYBACK")
        navigator.audioSession.type = 'playback';
      }

      // Set the current recognition text
      setCurrentRecognition(data.completedText);

      // Set the audio element src attribute and start playback
      audioRef.current.src = url + `/${data.url}`;
      audioRef.current.crossOrigin = "anonymous";
      audioRef.current.play();

      // Store the session ID if it's a new session
      if (data.newSession) {
        localStorage.setItem('sessionId', data.sessionId);
      }
    });

    socket.on("playSong", () => {
      // console.log("playsong")
      // setIsTalking(true)
      // setIsThinking(false)

      audioRef.current.src = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/JEET/JITTO_Jingle.wav'
      audioRef.current.crossOrigin = "anonymous"
      audioRef.current.play();
      setCurrentRecognition("♪")
    });

    socket.on("serverError", () => {
      // console.log("should error msg", shouldErrorMsgPlay.current)
      if (shouldErrorMsgPlay.current) {
        setVisemeData(ServerError)
        setIsTalking(true)

        // console.log("Server Error")

        audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/butati_error.wav"
        audioRef.current.crossOrigin = "anonymous"
        audioRef.current.play();
        setCurrentRecognition("Sorry, I didn't get that. Can you say it again?")
        // console.log("recording started, errormsg true and record again true")
      }
      else {
        setCurrentRecognition("")
      }
      setResponseReady(true)
      setIsThinking(false)
      shouldRecordAgain.current = false
      shouldErrorMsgPlay.current = true


      // console.log("recording started, errormsg false and record again false")
    })

    socket.on("disconnect", () => {
      // console.log("disconnected", socket.id);
    });


    socket.on("final", () => {

      if (recordingStoppedByTimerRef.current === false) {
        // console.log("FINAL", recordingStoppedByTimerRef.current);
        clearTimeout(timeoutRef.current);


        if (connection) connection.emit("endStream");
        setIsThinking(true)
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
          audioContextRef.current.close()
        }

        if (navigator.audioSession) {
          // setplaybaclIOSText("PLAYBACK")
          navigator.audioSession.type = 'playback';
        }

        audioRefAlt.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/stop-13692.mp3"
        audioRefAlt.current.crossOrigin = "anonymous"
        audioRefAlt.current.play();

        setIsRecording(false);
        setResponseReady(false)
      }
    });


    return () => {
      socket.disconnect();
    };
  }, []);


  // // RESPONSE CONTAINER SCROLL ADJUSTMENT
  // const responseContRef = useRef(null);
  // useEffect(() => {
  //   // Add a new useEffect hook to update the styles of the responseCont element
  //   const responseCont = responseContRef.current;

  //   function updateStyles() {
  //     if (responseCont.scrollHeight > responseCont.clientHeight) {
  //       // Content is overflowing, remove the "centered" class
  //       responseCont.classList.remove("centered");
  //     } else {
  //       // Content is not overflowing, add the "centered" class
  //       responseCont.classList.add("centered");
  //     }
  //   }

  //   // Call the updateStyles function whenever the content of the container changes
  //   responseCont.addEventListener("DOMSubtreeModified", updateStyles);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     responseCont.removeEventListener("DOMSubtreeModified", updateStyles);
  //   };
  // }, [])


  // RECORDING BUTTON UI
  useEffect(() => {
    const recButton = document.querySelector('.ui-rec-btn')
    if (isRecording) {
      recButton.classList.add('active')
      setButtonText("Listening...")
      setStartingToRecord(false)
    }
    else {
      recButton.classList.remove('active')
      setButtonText("Tap to speak")
    }
  }, [isRecording])


  // STOP RECORDING FUNCTIONALITY
  // useEffect(() => {
  //   const handlePointerUp = () => {
  //     // console.log("Stopped Recording");
  //     setButtonText("Hold to speak")
  //     if (!isRecording) return;
  //     if (connection) connection.emit("endStream");
  //     processorRef.current?.disconnect();
  //     audioInputRef.current?.disconnect();
  //     if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
  //       audioContextRef.current.close()
  //     }

  //     if (navigator.audioSession) {
  //       setplaybaclIOSText("PLAYBACK")
  //       navigator.audioSession.type = 'playback';
  //     }

  //     setIsRecording(false);
  //   };

  //   document.addEventListener("pointerup", handlePointerUp);
  //   document.addEventListener("touchend", handlePointerUp);

  //   return () => {
  //     document.removeEventListener("pointerup", handlePointerUp);
  //     document.addEventListener("touchend", handlePointerUp);
  //   };
  // }, [connection, isRecording]);

  const timeoutRef = useRef(null);

  const stopRecording = () => {
    // console.log("Stopped Recording");
    // setButtonText("Hold to speak")
    if (!isRecording) return;
    if (connection) connection.emit("endStream");
    setIsThinking(true)
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close()
    }

    if (navigator.audioSession) {
      // setplaybaclIOSText("PLAYBACK")
      navigator.audioSession.type = 'playback';
    }

    setIsRecording(false);
  }

  useEffect(() => {
    if (!responseReady) {
      setButtonText("Thinking...")
    } else {
      setButtonText("Tap to speak")
    }
  }, [responseReady])

  // START RECORDING METHOD
  const startRecording = async () => {

    if (isRecording) {
      // stopRecording()
      return;
    }

    setStartingToRecord(true)
    shouldRecordAgain.current = true
    // console.log("recording started, errormsg true and record again true", shouldRecordAgain.current)
    recordingStoppedByTimerRef.current = false

    await stopAudio()
    // setButtonText("Release to stop")
    setCurrentRecognition("...")

    if (!connection || isRecording) return;

    // if (!playbackConsent) {
    // setPlaybackConsent(true);
    audioRefAlt2.current.src = "data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQAAAAA=";
    audioRefAlt2.current.play();
    // }

    if (navigator.audioSession) {
      // setplaybaclIOSText("PLAY AND RECORD")
      // The Audio Session API is supported
      navigator.audioSession.type = 'play-and-record';
    }

    let newSession = localStorage.getItem('sessionId') === null
    let sessionId = newSession ? 0 : localStorage.getItem('sessionId')

    // console.log(props.language === "EN" ? "en-IN" : "hi-IN", sessionId, newSession)

    // console.log(currentLocation, previousLocation !== currentLocation)
    if (language === "English") {
      connection.emit("startStream", "en-IN", sessionId, currentLocation, newSession, previousLocation !== currentLocation);
    }
    else if (language === "Hindi") {
      connection.emit("startStream", "hi-IN", sessionId, currentLocation, newSession, previousLocation !== currentLocation);
    }
    else {
      connection.emit("startStream", "fr-FR", sessionId, currentLocation, newSession, previousLocation !== currentLocation);
    }

    if (previousLocation !== currentLocation) {
      setPreviousLocation(currentLocation)
    }

    const stream = await getMediaStream();
    audioContextRef.current = new window.AudioContext();

    await audioContextRef.current.audioWorklet.addModule(
      "/src/worklets/recorderWorkletProcessor.js"
    );

    audioContextRef.current.resume();
    audioInputRef.current = audioContextRef.current.createMediaStreamSource(
      stream
    );

    processorRef.current = new AudioWorkletNode(
      audioContextRef.current,
      "recorder.worklet"
    );

    processorRef.current.connect(audioContextRef.current.destination);
    audioContextRef.current.resume();
    audioInputRef.current.connect(processorRef.current);

    processorRef.current.port.onmessage = (event) => {
      const audioData = event.data;
      connection.emit("send_audio_data", { audio: audioData });
    };

    audioRefAlt.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/start-13691.mp3"
    audioRefAlt.current.crossOrigin = "anonymous"
    audioRefAlt.current.play();

    setIsRecording(true);

    timeoutRef.current = setTimeout(() => {
      // console.warn('STOPPER FORCEFULLY');
      recordingStoppedByTimerRef.current = true
      // console.warn(recordingStoppedByTimerRef.current)
      if (connection) connection.emit("endStream");
      setIsThinking(true)
      processorRef.current?.disconnect();
      audioInputRef.current?.disconnect();
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close()
      }

      if (navigator.audioSession) {
        // setplaybaclIOSText("PLAYBACK")
        navigator.audioSession.type = 'playback';
      }

      audioRefAlt.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/stop-13692.mp3"
      audioRefAlt.current.crossOrigin = "anonymous"
      audioRefAlt.current.play();

      setIsRecording(false);
      setResponseReady(false)


      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, 5000);
  };


  // useEffect(() => {
  //   let isMounted = true;

  //   // Inside the useEffect, use the isMounted variable
  //   // to check whether the component is still mounted.
  //   setTimeout(() => {
  //     if (isMounted) {
  //       setIsTalking(true)
  //       audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/Butati_Greet_1.wav";
  //       audioRef.current.play();
  //       setCurrentRecognition("Jai Jinendra, Welcome to the Butati bungalow! I'm Biju, a virtual AI bot created by XR Vizion, and I can't wait to embark on this journey with you")
  //     }
  //   }, 3000);

  //   // The cleanup function will set isMounted to false,
  //   // indicating that the component is unmounted.
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  useEffect(() => {
    let isMounted = true;
    const exteriorIntroPlayed = localStorage.getItem("exteriorIntroPlayed")

    if (isMobileDevice && !exteriorIntroPlayed) {

      // Inside the useEffect, use the isMounted variable
      // to check whether the component is still mounted.
      setTimeout(() => {
        if (isMounted) {
          setVisemeData(ExteriorGreet);
          setIsTalking(true)
          audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/synthesis_2.wav";
          audioRef.current.play();
          setCurrentRecognition("Jai Jinendra, Welcome to the Abhushan mahal! I'm Araham, a virtual AI bot created by XR Vizion, and I can't wait to embark on this journey with you.")
          localStorage.setItem("exteriorIntroPlayed", true)
        }
      }, 4000);
    }
    else if (!isMobileDevice) {

      // Inside the useEffect, use the isMounted variable
      // to check whether the component is still mounted.
      setTimeout(() => {
        if (isMounted) {
          setVisemeData(ExteriorGreet);
          setIsTalking(true)
          audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/synthesis_2.wav";
          audioRef.current.play();
          setCurrentRecognition("Jai Jinendra, Welcome to the Abhushan mahal! I'm Araham, a virtual AI bot created by XR Vizion, and I can't wait to embark on this journey with you.")
        }
      }, 4000);
    }

    // The cleanup function will set isMounted to false,
    // indicating that the component is unmounted.
    return () => {
      isMounted = false;
    };
  }, []);



  useEffect(() => {
    const foyerIntroPlayed = localStorage.getItem("foyerIntroPlayed")
    const courtyardIntroPlayed = localStorage.getItem("courtyardIntroPlayed")

    if (isMobileDevice) {
      if (currentLocation === "Foyer" && !foyerIntroPlayed) {
        stopAudio()
        setTimeout(() => {
          setVisemeData(ResidentialGreet);
          setIsTalking(true)
          audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/Butati_common_Area.wav";
          audioRef.current.play();
          setCurrentRecognition("Welcome to the Foyer, a central space with a luxurious stairway connecting you to all rooms in the Residential Area and featuring an atrium ceiling that bathes the area in natural light.")
        }, 3000);
        localStorage.setItem("foyerIntroPlayed", true)

      }
      else if (currentLocation === "Courtyard" && !courtyardIntroPlayed) {
        stopAudio()
        setTimeout(() => {
          setVisemeData(CourtyardGreet);
          setIsTalking(true)
          audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/synthesis_courtyard.wav";
          audioRef.current.play();
          setCurrentRecognition("Step into the magnificent courtyard of our hospitality area, inviting you to immerse yourself in a world of opulence and comfort.")
        }, 5000);
        localStorage.setItem("courtyardIntroPlayed", true)

      }
    }
    else {
      if (currentLocation === "Foyer" && shouldPlayCommonAreaMsg) {
        stopAudio()
        setTimeout(() => {
          setVisemeData(ResidentialGreet);
          setIsTalking(true)
          audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/Butati_common_Area.wav";
          audioRef.current.play();
          setCurrentRecognition("Welcome to the Foyer, a central space with a luxurious stairway connecting you to all rooms in the Residential Area and featuring an atrium ceiling that bathes the area in natural light.")
        }, 3000);
        setShouldPlayCommonAreaMsg(false)
      }
      else if (currentLocation === "Courtyard" && shouldPlayCourtyardMsg) {
        stopAudio()
        setTimeout(() => {
          setVisemeData(CourtyardGreet);
          setIsTalking(true)
          audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/synthesis_courtyard.wav";
          audioRef.current.play();
          setCurrentRecognition("Step into the magnificent courtyard of our hospitality area, inviting you to immerse yourself in a world of opulence and comfort.")
        }, 5000);
        setShouldPlayCourtyardMsg(false)
      }
    }
  }, [currentLocation])


  const handleKeyDown = (event) => {
    // Prevent default action for the space bar key
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
    }
  };



  return (
    <>
      {/* <h1 style={{ color: "white", display: "none" }}>{playbackIOSText}</h1> */}
      {/* <div className="response-cont" ref={responseContRef} style={{ display: "none" }}>
        <div className="gc-response ff-l" style={{ marginLeft: "0.5rem" }}>
          {currentRecognition === "..." ?
            <BeatLoader
              color="#fff"
              margin={2.25}
              size={7.5}
              speedMultiplier={0.8}
            /> : currentRecognition}</div>
      </div>
      <div className="recording-cont">
        <button disabled={startingToRecord} className="ui-rec-btn active ff-m" style={{ display: "none" }} onClick={startRecording}>
          <IoMdMic size={"1.65rem"} />
        </button>
        <div className="info-text ff-l">
          {buttonText}
        </div>
      </div> */}

      <div className="res-container">
        <div className="res-data ff-m fs-sm">
          {
            currentRecognition === "..."
              ?
              <BeatLoader
                color="#fff"
                size={7.5}
                speedMultiplier={0.8}
              />
              :
              currentRecognition
          }
        </div>

        <div className="recBtn-container">
          <button disabled={startingToRecord || responseReady === false} className="ui-rec-btn ff-m" onClick={startRecording} onKeyDown={handleKeyDown}>
            <IoMdMic size={isMobileDevice ? '1rem' : "1.4025rem"} />
          </button>
          <div className="info-text ff-l">
            {buttonText}
          </div>
        </div>
      </div>


    </>
  );
};

export default AudioToText;
