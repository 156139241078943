import React, { useEffect, useState, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ReactStars from "react-rating-stars-component";
import BeatLoader from "react-spinners/BeatLoader"
import axios from "axios";
import { CgOptions, CgClose } from 'react-icons/cg'

import 'bootstrap/dist/css/bootstrap.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { IoHelp } from 'react-icons/io5'
import { BiFullscreen } from 'react-icons/bi'
import { IoLocationOutline } from 'react-icons/io5'
import { LiaLanguageSolid } from 'react-icons/lia'


import "./App.css";
import BotContainer from "./Components/BotContainer";
import BotCanvas from "./Components/BotCanvas";
import AudioToText from "./Components/AudioToText";
import Navigation from "./Components/Navigation";
import FactLoader from "./Components/FactLoader";
import Exterior from "./Components/Scenes/Exterior";
import Foyer from "./Components/Scenes/Foyer";
import Courtyard from "./Components/Scenes/Courtyard";
import MobileBuild from "./Components/Scenes/MobileBuild";

function App() {

  const [isAuth, setIsAuth] = useState(false);
  let authRequestSent = false

  const [isLoading, setIsLoading] = useState(false);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [playTriggered, setPlayTriggered] = useState(false);

  // Login form
  const [form, setForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [otpForm, setOTPForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOTPError, setShowOTPError] = useState("");
  const [sendingOTP, setSendingOTP] = useState(false);

  // Help modal
  const [showhelp, setShowHelp] = useState(false);

  const [showLanguageChange, setShowLanguageChange] = useState(false);

  // Feedback
  const [showFeedback, setShowFeedback] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(true);
  const [showFeedbackError, setShowFeedbackError] = useState("");
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState(false);

  const [isTalking, setIsTalking] = useState(false)
  const [isThinking, setIsThinking] = useState(false)
  const [isListening, setIsListening] = useState(false)

  const [currentLocation, setCurrentLocation] = useState("Exterior")
  // console.log(isTalking)

  const [startClicked, setStartClicked] = useState(false)
  const [isHovered, setIsHovered] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [visemeData, setVisemeData] = useState();
  const [exteriorLoaded, setExteriorLoaded] = useState(false);

  const data = [{ id: 0, label: "English" }, { id: 1, label: "Hindi" }, { id: 2, label: "French" }];

  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState(0);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setOpen(false)
  }

  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
    else {
      setIsMenuOpen(true)
    }
  }

  const checkStatus = async (emailAddress) => {
    authRequestSent = true;
    try {
      const response = await axios.post(
        "https://api.xrvizion.com/user/authorize",
        { email: emailAddress }
        , { withCredentials: true }
      );
      if (response.data.isUserAuthorized) {
        setIsAuth(true)
      }
      else {
        setIsAuth(false)
      }

      setIsLoading(false)
    } catch (error) {
      // console.error(error);
      setIsAuth(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // let emailAddress = localStorage.getItem('email');
    // if (emailAddress && !authRequestSent) {
    //   setIsLoading(true)
    //   checkStatus(emailAddress)
    // }
    // window.addEventListener('resize', function () {
    //   window.location.reload();
    // });
    document.addEventListener('keydown', function (event) {
      // Check if the feedback form is currently open
      if (showFeedback) {
        // Prevent the default behavior of the keydown event
        event.preventDefault();
      }
    });
  }, [])

  const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      // return window.innerWidth < 1000;
      return false;
    }
  }

  const isMobileDevice = isMobile();

  //SWITCH CHANGES
  const [loadingProgression, setLoadingProgression] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const sendMessageRef = useRef();

  const [build, setBuild] = useState("Exterior")

  const [reloadBuild, setReloadBuild] = useState(false)

  const [unloadExterior, setUnloadExterior] = useState(false)
  const [unloadFoyer, setUnloadFoyer] = useState(false)
  const [unloadCourtyard, setUnloadCourtyard] = useState(false)

  const [readyToLoad, setReadyToLoad] = useState("Exterior")

  const [shouldPlayCommonAreaMsg, setShouldPlayCommonAreaMsg] = useState(true)

  const [stopAudioWhileSwitching, setStopAudioWhileSwitching] = useState(false)

  const [foyerKey, setFoyerKey] = useState(false)
  const [courtyardKey, setCourtyardKey] = useState(false)

  useEffect(() => {
    // console.log("readyToLoad", readyToLoad)
    setBuild(readyToLoad)
  }, [readyToLoad])

  const sceneLoadingVideo = useRef("")

  if (isMobileDevice) {
    sceneLoadingVideo.current = localStorage.getItem("sceneLoadingVideo") || "Exterior"
  }
  else {
    sceneLoadingVideo.current = "Exterior"
  }

  const sceneToLoad = useRef("Exterior")

  const switchBuild = (scene) => {

    if (scene === "Exterior") {
      sceneLoadingVideo.current = "Exterior"
    }
    else {
      sceneLoadingVideo.current = "Foyer"
    }

    if (isLoading) return

    sceneToLoad.current = scene

    if (scene === "Exterior") {
      if (build === "Exterior") return;
      setStopAudioWhileSwitching(true)
      // setUnloadFoyer(true)
      // setUnloadCourtyard(true)
      setIsLoading(true)
    }

    else if (scene === "Foyer") {
      setStopAudioWhileSwitching(true)
      // setUnloadExterior(true)
      // setUnloadCourtyard(true)
      setIsLoading(true)
    }

    else if (scene === "Courtyard") {
      setStopAudioWhileSwitching(true)
      // setUnloadExterior(true)
      // setUnloadFoyer(true)
      setIsLoading(true)
    }

    // console.warn("UNLOADING", build)
    if (build === "Exterior") {
      setUnloadExterior(true)
    }
    else if (build === "Foyer") {
      setUnloadFoyer(true)
    }
    else if (build === "Courtyard") {
      setUnloadCourtyard(true)
    }

    setReloadBuild(true)

    // if (build === "Foyer") {
    //   setFoyerKey(prev => !prev)
    //   return
    // };

    // if (build === "Courtyard") {
    //   setCourtyardKey(prev => !prev)
    //   return
    // };
  }

  // const handleClick = () => {
  //   console.clear();
  //   if (build === "Exterior") {
  //     setStopAudioWhileSwitching(true)
  //     setUnloadExterior(true)
  //     setIsLoading(true)
  //   }
  //   else {
  //     setStopAudioWhileSwitching(true)
  //     setUnloadFoyer(true)
  //     setIsLoading(true)
  //   }
  // }


  // Change scenes from react
  function triggerUnityFunction(levelIndex, sceneName) {
    // sendMessage('Annotations', 'LoadLevelWithIndex', levelIndex);
    sendMessageRef.current('Annotations', 'LoadLevelWithIndex', levelIndex);

    if (sceneName) {
      // console.warn(sceneName)
      setCurrentLocation(sceneName);
    }
  }

  // Room info is received here
  useEffect(() => {
    // Define your event handler
    const handleEnterRoom = (event) => {
      // console.warn("ROOM:", event.detail);
      setCurrentLocation(event.detail);
    };

    // Add the event listener
    window.addEventListener('EnterRoom', handleEnterRoom);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('EnterRoom', handleEnterRoom);
    };
  }, []);


  // Switch build info is received here
  useEffect(() => {
    // Define your event handler
    const handleLoadRoom = (event) => {
      // console.warn("ROOM:", event.detail);
      switchBuild(event.detail)
    };

    // Add the event listener
    window.addEventListener('LoadRoom', handleLoadRoom);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('LoadRoom', handleLoadRoom);
    };
  }, []);



  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        setReadyToPlay(true);
        console.clear()
      }, 2000);

      if (!playTriggered) {
        // sendMessage('InputHandler', 'SetCaptureInput', 'false');
        sendMessageRef.current('InputHandler', 'SetCaptureInput', 'false');
      }
    }
  }, [isLoaded])

  useEffect(() => {
    if (playTriggered) {
      // sendMessage('InputHandler', 'SetCaptureInput', 'true');
      // sendMessageRef.current('InputHandler', 'SetCaptureInput', 'true');
    }
  }, [playTriggered])


  useEffect(() => {
    if (readyToPlay && playTriggered) {
      const canvas = document.querySelector("canvas");
      if (canvas) {
        canvas.style.display = "block";
        // setShowHelp(true)
      }
      setIsLoading(false);
      setReadyToPlay(false)
      setIsLoaded(false)

      if (!exteriorLoaded) {
        setExteriorLoaded(true)
      }

      // const getFeedbackStatus = async () => {
      //   try {
      //     let emailID = localStorage.getItem("email");
      //     const response = await axios.post(
      //       "https://api.xrvizion.com/user/getfeedback",
      //       { email: emailID }
      //     );
      //     setFeedbackSubmitted(response.data.isFeedbackSet);
      //   } catch (error) {
      //     // console.error(error);
      //   }
      // };

      // getFeedbackStatus();
    }

  }, [readyToPlay, playTriggered])

  const handleBeforeUnloadRef = useRef();

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {

  //     localStorage.clear();

  //     if (!feedbackSubmitted) {
  //       e.preventDefault();
  //       e.returnValue = "";
  //       setShowFeedback(true);
  //       // sendMessage('InputHandler', 'SetCaptureInput', 'false');
  //       sendMessageRef.current('InputHandler', 'SetCaptureInput', 'false');
  //     }
  //   };
  //   handleBeforeUnloadRef.current = handleBeforeUnload;
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [feedbackSubmitted]);

  const handleButtonClick = () => {
    setIsLoading(true);
    // sendMessage('InputHandler', 'SetCaptureInput', 'false');
    sendMessageRef.current('InputHandler', 'SetCaptureInput', 'false');

    const resumeGame = localStorage.getItem("resumeGame")

    if (isMobileDevice) {
      if (!resumeGame) {
        setForm(true);
        localStorage.setItem("resumeGame", true)
      } else {
        setPlayTriggered(true);
      }
    }
    else {
      if (isAuth) {
        setPlayTriggered(true);
      } else {
        setForm(true);
      }
    }

    setStartClicked(true)
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    setShowError("")

    try {
      setSendingOTP(true);
      const response = await axios.post("https://api.xrvizion.com/user/setuser", { name, email }, { withCredentials: true });
      // console.log(response)
      if (response.data.msg === "Success") {
        setOTPForm(true);
      } else {
        // Handle error response
        setShowError(response.data.msg);
      }
      setSendingOTP(false);
    }
    catch (error) {
      // // Handle network error
      // console.log(error.response.data.msg);
      setShowError(error.response.data.msg);
      setSendingOTP(false);
    }

  }

  const verifyOTP = async (e) => {
    e.preventDefault();

    // try {
    setSubmitting(true)
    setSendingOTP(true)
    console.clear()
    // const response = await axios.post("https://api.butati.xrvizion.com/user/setuser", { name, email }, { withCredentials: true });
    // // console.log(response)
    // if (response.status === 200) {
    //   // console.log(response)
    setForm(false);
    setPlayTriggered(true);
    // localStorage.setItem("email", email);
    // localStorage.setItem("name", name);
    setSendingOTP(false)
    // sendMessage('InputHandler', 'SetCaptureInput', 'true');
    // sendMessageRef.current('InputHandler', 'SetCaptureInput', 'true');
    setShowHelp(true)
    //   } else {
    //     // Handle error response
    //     console.log(response)
    //     if (response.status === 401) {
    //       setShowOTPError("Please try again.");
    //     } else {
    //       setShowOTPError(response.data.msg);
    //     }
    //     setSendingOTP(false)
    //   }
    //   setSubmitting(false);
    // } catch (error) {
    //   // Handle network error
    //   // console.log(error.response.data.msg);
    //   if (error.response.status === 401) {
    //     setShowOTPError("Please try again.");
    //   } else {
    //     setShowOTPError(error.response.data.msg);
    //   }
    //   setSubmitting(false);
    // }

  }

  // Utility functions
  const closeHelp = () => {
    setShowHelp(false);
    setIsMenuOpen(false)
  }

  const ratingChanged = (newRating) => {
    setRating(newRating)
  }

  const submitFeedback = async () => {
    setShowFeedbackError("");
    setSubmittingFeedback(true)
    try {
      let emailID = localStorage.getItem("email");
      const response = await axios.post(
        "https://api.xrvizion.com/user/setfeedback",
        { email: emailID, message: feedback, stars: rating },
        { withCredentials: true }
      );
      if (response.data.msg === "Success") {
        // Remove beforeunload event listener
        window.removeEventListener("beforeunload", handleBeforeUnloadRef.current);
        // Reload the page
        // window.location.reload();
        setShowFeedback(false);
        // sendMessage('InputHandler', 'SetCaptureInput', 'true');
        // sendMessageRef.current('InputHandler', 'SetCaptureInput', 'true');
        setSuccessFeedback(true);
      } else {
        // Handle error
        setShowFeedbackError(response.data.msg);
      }
      setSubmittingFeedback(false)
    } catch (error) {
      // Handle network error
      setShowFeedbackError(error.message);
      setSubmittingFeedback(false)
    }
  };

  const setContainerHeights = () => {
    const container = document.querySelector('.container');

    const viewportHeight = window.innerHeight;
    container.style.height = `${viewportHeight * 1}px`;
  };

  useEffect(() => {
    window.addEventListener('resize', setContainerHeights);
    setContainerHeights();

    return () => {
      window.removeEventListener('resize', setContainerHeights);
    };
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const node = useRef();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click 
    setIsMenuOpen(false);
    setShowLanguageChange(false)
  };


  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isNavigation, setIsNavigation] = useState(false)

  useEffect(() => {
    if (isNavigation) {
      setShowHelp(false);
      setShowLanguageChange(false);
    }
  }, [isNavigation]);

  useEffect(() => {
    if (showhelp) {
      setIsNavigation(false);
      setShowLanguageChange(false);
    }
  }, [showhelp]);

  useEffect(() => {
    if (showLanguageChange) {
      setShowHelp(false);
      setIsNavigation(false);
    }
  }, [showLanguageChange]);


  return (
    <>
      <div className="container">

        {/* <img className="background-image" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/bg3.jpg" alt="Background" /> */}

        <video key={sceneLoadingVideo.current} className="background-image" autoPlay muted loop playsInline>
          <source src={`https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/loading-videos/${sceneLoadingVideo.current}.mov`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="overlay"></div>

        {/* LOGIN */}
        {form ?
          otpForm
            ?
            <form className="form" onSubmit={verifyOTP}>
              <div className="form-header" style={{ textAlign: "center" }}>
                <div className="ff-m fs-sm" style={{ wordSpacing: "0.2rem", letterSpacing: "0.03rem" }}>ENTER LOGIN CODE</div>
                <div className="ff-l fs-xxs" style={{ wordSpacing: "0.05rem", letterSpacing: "0.03rem", marginTop: "0.5rem" }}>We've sent a code to {email}</div>
              </div>
              <label>
                <input required style={{ letterSpacing: "0.03rem" }} className="ff-m fs-xxs" type="text" name="name" placeholder="__     __     __     __     __     __" maxLength={6} autocomplete="off" value={otp} onChange={(e) => { setOtp(e.target.value); setShowOTPError("") }} />
                {showOTPError && <span className="ff-l fs-xxs" style={{ color: "white", marginLeft: "0.3rem", opacity: 0.5, letterSpacing: "0.02rem", wordSpacing: "0.03rem", textAlign: "center" }}>{showOTPError}</span>}
              </label>
              <button style={{ letterSpacing: "0.03rem", width: "auto", padding: "0.5rem 4rem" }} disabled={submitting} type="submit" className="button-alt ff-m fs-xs">
                {
                  submitting
                    ?
                    <BeatLoader
                      color="#fff"
                      size={"0.35vw"}
                      speedMultiplier={1}
                    />
                    :
                    "CONTINUE"
                }
              </button>
            </form>
            :
            <form className="form home-form" onSubmit={verifyOTP}>
              <div className="form-header ff-m fs-sm" style={{ wordSpacing: "0.2rem", letterSpacing: "0.03rem" }}>Welcome to Abhushan Mahal</div>
              <div className="form-header ff-l fs-xxs" style={{ wordSpacing: "0.2rem", letterSpacing: "0.03rem", marginTop: "-2rem", opacity: "0.5" }}>Enter your name and email to get started</div>
              <label>
                {/* NAME */}
                <input required style={{ letterSpacing: "0.03rem" }} className="ff-m fs-xxs" type="text" name="name" placeholder="NAME" autocomplete="off" value={name} onChange={(e) => setName(e.target.value)} />
              </label>
              <label>
                {/* EMAIL */}
                <input required style={{ letterSpacing: "0.03rem" }} className="ff-m fs-xxs" type="email" name="email" placeholder="EMAIL" autocomplete="off" value={email} onChange={(e) => { setEmail(e.target.value); setShowError("") }} />
                {showError && <span className="ff-l fs-xxs" style={{ color: "white", marginLeft: "0.3rem", opacity: 0.5, letterSpacing: "0.02rem", wordSpacing: "0.03rem" }}>{showError}</span>}
              </label>
              <button style={{ letterSpacing: "0.03rem", width: "auto", padding: "0.5rem 4rem" }} disabled={sendingOTP} type="submit" className={`button-alt ff-m fs-xs ${sendingOTP ? "sending-otp" : ""}`}>
                {
                  sendingOTP
                    ?
                    <BeatLoader
                      color="#000"
                      size={"0.35vw"}
                      speedMultiplier={1}
                    />
                    :
                    "CONTINUE"
                }
              </button>
            </form>
          :
          isLoading ?
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <button className="button fs-xs">
                <div className="ff-m" style={{ letterSpacing: "0.02rem" }}>
                  LOADING <br />{Math.round(loadingProgression * 100)}%
                </div>
              </button>

              <FactLoader />
            </div>
            :
            <button className="button fs-sm" onClick={handleButtonClick}>
              <div className="ff-m" style={{ letterSpacing: "0.03rem" }}>
                {
                  isMobileDevice
                    ?
                    localStorage.getItem("resumeGame")
                      ?
                      <span>ENTER</span>
                      :
                      <span>ENTER <br /> ABHUSHAN <br /> MAHAL</span>
                    :
                    <span>ENTER <br /> ABHUSHAN <br /> MAHAL</span>
                }
              </div>
            </button>
        }


        {/* UNITY APP */}
        {/* {playTriggered &&  */}
        {/* <Unity unityProvider={unityProvider} /> */}
        {/* <button onClick={handleClick} style={{ position: "absolute", zIndex: "10000000", top: "6rem", left: "2rem" }}>Switch</button> */}

        {
          isMobileDevice
            ?
            <MobileBuild reloadBuild={reloadBuild} unloadExterior={unloadExterior} setLoadingProgression={setLoadingProgression} setIsLoaded={setIsLoaded} setUnloadExterior={setUnloadExterior} setReadyToLoad={setReadyToLoad} sendMessageRef={sendMessageRef} sceneToLoad={sceneToLoad} isMobileDevice={isMobileDevice} setBuild={setBuild} setReloadBuild={setReloadBuild} setCurrentLocation={setCurrentLocation} />
            :
            build === "Exterior" ?

              <Exterior unloadExterior={unloadExterior} setLoadingProgression={setLoadingProgression} setIsLoaded={setIsLoaded} setUnloadExterior={setUnloadExterior} setReadyToLoad={setReadyToLoad} sendMessageRef={sendMessageRef} sceneToLoad={sceneToLoad} isMobileDevice={isMobileDevice} /> :

              build === "Foyer" ?

                <Foyer key={foyerKey} setFoyerKey={setFoyerKey} unloadFoyer={unloadFoyer} setLoadingProgression={setLoadingProgression} setIsLoaded={setIsLoaded} setUnloadFoyer={setUnloadFoyer} setReadyToLoad={setReadyToLoad} sendMessageRef={sendMessageRef} sceneToLoad={sceneToLoad} isMobileDevice={isMobileDevice} /> :

                <Courtyard key={courtyardKey} unloadCourtyard={unloadCourtyard} setLoadingProgression={setLoadingProgression} setIsLoaded={setIsLoaded} setUnloadCourtyard={setUnloadCourtyard} setReadyToLoad={setReadyToLoad} sendMessageRef={sendMessageRef} sceneToLoad={sceneToLoad} setCurrentLocation={setCurrentLocation} isMobileDevice={isMobileDevice} />
        }
        {/* } */}

        {/* LOGO */}
        {/* <div className="logo">
          <a href="https://xrvizion.com" target="_blank" rel="noreferrer">
            <img style={{ width: "calc(1.35rem * 3)", top: "2rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/abushan.png" alt="" />
          </a>
          <a href="https://xrvizion.com" target="_blank" rel="noreferrer">
            <img style={{ width: "calc(2.15rem * 3)", top: "3.15rem", left: "7rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/xrc-logo.png" alt="" />
          </a>
        </div> */}

        <div className="header-logo">
          <a href="https://xrvizion.com" target="_blank" rel="noreferrer">
            <img className="abhushan-logo-res" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/abushan.png" alt="" />
          </a>
          <a href="https://xrvizion.com" target="_blank" rel="noreferrer">
            <img className="xrv-logo-res" style={{ marginLeft: "1rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/xrc-logo.png" alt="" />
          </a>
        </div>

        {
          // (playTriggered && readyToPlay) &&
          <>
            {/* <img className="overlay-btn-img-1" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/help-1.png" alt="" /> */}

            <div className="menu" ref={node}>
              <button onClick={toggleMenu} className="overlay-button fs-xs ff-m"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: isHovered ? 'black' : isMenuOpen ? 'black' : !isHovered && 'white',
                  background: isHovered ? 'white' : isMenuOpen ? 'white' : !isHovered && 'initial'
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {
                  isMenuOpen
                    ?
                    <CgClose size={"1.25rem"} />
                    :
                    <CgOptions size={"1.25rem"} />
                }
              </button>

              {
                isMenuOpen &&
                <div className="menu-container">
                  <OverlayTrigger
                    delay={{ hide: 0, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        full screen
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <button onClick={toggleFullScreen} className="overlay-button-alt fs-xs" style={{ backgroundColor: isFullScreen ? "#cb3741ec" : "initial" }}>
                      <BiFullscreen size={isMobileDevice ? "1.25rem" : "1rem"} />
                    </button>
                  </OverlayTrigger>


                  <OverlayTrigger
                    delay={{ hide: 0, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        navigation
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <button className="overlay-button-alt fs-xs" onClick={() => setIsNavigation(isNavigation ? false : true)} style={{ backgroundColor: isNavigation ? "#cb3741ec" : "initial" }}>
                      <IoLocationOutline size={isMobileDevice ? "1.25rem" : "1rem"} />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    delay={{ hide: 0, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        help
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <button className="overlay-button-alt fs-xs" onClick={() => setShowHelp(showhelp ? false : true)} style={{ backgroundColor: showhelp ? "#cb3741ec" : "initial" }}>
                      <IoHelp size={isMobileDevice ? "1.25rem" : "1rem"} />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    delay={{ hide: 0, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        language
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <button className="overlay-button-alt fs-xs" onClick={() => setShowLanguageChange(showLanguageChange ? false : true)} style={{ backgroundColor: showLanguageChange ? "#cb3741ec" : "initial" }}>
                      <LiaLanguageSolid size={isMobileDevice ? "1.25rem" : "1rem"} />
                    </button>
                  </OverlayTrigger>
                </div>
              }

              {/* HELP MODAL */}
              {showhelp &&
                <div className="help-modal" style={{ color: "white" }}>
                  <div className="htp-images" style={{ textAlign: isMobileDevice ? "center" : "unset", transform: isMobileDevice ? "translateX(-0.3rem)" : "unset" }}>
                    {
                      isMobileDevice ?
                        <>
                          <div className="htp-images-cont">
                            <img className="htp-image" style={{ width: "5rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/hand-move.png" alt="move" />
                          </div>
                        </>
                        :
                        <>
                          <div className="htp-images-cont">
                            <img className="htp-image" style={{ width: "2.4rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Metadrip/mouse1.png" alt="keys" />
                            <div className="ff-l fs-xxs">Mouse</div>
                          </div>
                          <div className="htp-images-cont">
                            <img className="htp-image" style={{ width: "6rem", marginTop: "1rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/keys2.png" alt="keys" />
                            <div className="ff-l fs-xxs">Keyboard</div>
                          </div>
                        </>
                    }
                  </div>

                  <div className="htp-content" style={{ color: "white", marginTop: "1.5rem", textAlign: "center" }}>
                    <div className={`htp-header ff-m ${isMobileDevice ? 'fs-lg' : 'fs-sm'}`} style={{ letterSpacing: "0.03rem" }}>HOW TO MOVE</div>
                    <div className={`htp-info ff-l ${isMobileDevice ? 'fs-lg' : 'fs-xs'}`} style={{ marginTop: "1rem", letterSpacing: "0.03rem" }}>{isMobileDevice ? "Double tap anywhere to move. Drag on the screen to change the view." : "Rotate using the left mouse button, zoom with the mouse wheel, and double click to reset the view. Inside, use WASD to move. Press E when near a door to enter that room."}</div>
                    <button onClick={closeHelp} className={`button-alt ff-m ${isMobileDevice ? 'fs-lg' : 'fs-xs'} htp-close`} style={{ width: "auto", padding: "0.5rem 3rem", letterSpacing: "0.03rem" }}>Close</button>
                  </div>
                </div>
              }

              {
                // (playTriggered && readyToPlay) &&
                (isNavigation && playTriggered) &&
                <Navigation triggerUnityFunction={triggerUnityFunction} switchBuild={switchBuild} isLoading={isLoading} build={build} />
              }


              {
                showLanguageChange &&
                <div className="help-modal" style={{ color: "white", display: "flex", justifyContent: "left", alignItems: "normal" }}>
                  <div className="" style={{ color: "white", textAlign: "left", letterSpacing: "0.05rem" }}>
                    <div className={`htp-header ff-m ${isMobileDevice ? 'fs-lg' : 'fs-sm'}`} style={{ letterSpacing: "0.03rem", fontSize: "1.05rem" }}>CHANGE LANGUAGE</div>
                    <div className='dropdown fs-m'>
                      <div className='dropdown-header' onClick={toggleDropdown}>
                        {/* {selectedItem ? items.find(item => item.id === selectedItem).label : "English"} */}
                        {items[selectedItem].label}
                        <i className={`fa fa-chevron-right icon ${isOpen && "open"} ${isMobileDevice ? 'fs-lg' : 'fs-xs'}`}></i>
                      </div>
                      <div className={`dropdown-body ${isOpen && 'open'}`}>
                        {items.map(item => (
                          <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                            <span className={`dropdown-item-dot ${item.id === selectedItem && 'selected'} ${isMobileDevice ? 'fs-lg' : 'fs-xs'}`}>• </span>
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <div className="htp-info ff-l fs-s" style={{ marginTop: "1rem", letterSpacing: "0.03rem" }}>{isMobileDevice ? "Double tap anywhere to move. Drag on the screen to change the view." : "Rotate using the left mouse button, zoom with the mouse wheel, and double click to reset the view. Inside, use WASD to move. Press E when near a door to enter that room."}</div> */}
                    {/* <button onClick={closeHelp} className="button-alt ff-m fs-s htp-close" style={{ width: "auto", padding: "0.5rem 3rem", letterSpacing: "0.03rem" }}>Close</button> */}
                  </div>
                </div>
              }
            </div>

            {/* <button
              className="overlay-button second-btn fs-m ff-m"
              style={{
                right: "5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={() => {
                window.open("https://linktr.ee/xrvizion", "_blank");
              }}
            >
              <img className="overlay-btn-img-2" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/info-1.png" alt="" />

            </button> */}
          </>
        }


        {/* FEEDBACK */}
        {
          showFeedback
          &&
          <div className="feedback-container" style={{ color: "white" }}>
            <div className="ff-m fs-sm" style={{ letterSpacing: "0.03rem", marginBottom: "0.5rem" }}>
              TELL US WHAT YOU THINK
            </div>

            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={window.innerWidth >= 1200 ? "70" : window.innerWidth >= 768 ? "50" : "40"}
              activeColor="#ffd700"
              // isHalf={true}
              color={"rgba(255, 255, 255, 0.3)"}
            />

            <div className="feedback" style={{ marginTop: "2rem" }}>
              <textarea rows={8} required style={{ letterSpacing: "0.03rem", width: "100%", resize: 'none' }} className="ff-m fs-xs" type="textarea" name="textarea" placeholder="HOW CAN WE IMPROVE?" value={feedback} autocomplete="off" onChange={(e) => setFeedback(e.target.value)} />
              <button onClick={submitFeedback} className="button-alt ff-m fs-sm">{submittingFeedback ?
                <BeatLoader
                  color="#fff"
                  size={"0.35vw"}
                  speedMultiplier={1}
                /> : "SUBMIT"}
              </button>
            </div>

            {showFeedbackError && <span className="ff-l fs-xxs" style={{ color: "white", marginLeft: "0.3rem", marginTop: "0.75rem", opacity: 0.5, letterSpacing: "0.02rem", wordSpacing: "0.03rem" }}>{showFeedbackError}</span>}
          </div>
        }

        {
          successFeedback
          &&
          <div className="feedback-container" style={{ color: "white" }}>
            <div className="ff-m fs-sm" style={{ letterSpacing: "0.03rem", marginBottom: "0.5rem" }}>
              FEEDBACK RECEIVED!
            </div>

            <div className="ff-l fs-xxs" style={{ wordSpacing: "0.05rem", letterSpacing: "0.03rem", marginTop: "0.5rem", textAlign: "center" }}>Thank you for taking the time to share your feedback with us. We appreciate your support.</div>

            <div className="feedback" style={{ marginTop: "3rem" }}>
              <button onClick={() => { setSuccessFeedback(false); window.location.reload(); }} style={{ marginTop: "0rem" }} className="button-alt ff-m fs-sm">CLOSE</button>
            </div>
          </div>
        }

      </div >

      {(playTriggered && exteriorLoaded) &&

        <div style={{ display: isLoading ? "none" : "block" }}>
          <div className="butati-response-container">
            <AudioToText setIsListening={setIsListening} setIsTalking={setIsTalking} setIsThinking={setIsThinking} currentLocation={currentLocation} startClicked={startClicked} shouldPlayCommonAreaMsg={shouldPlayCommonAreaMsg} setShouldPlayCommonAreaMsg={setShouldPlayCommonAreaMsg} stopAudioWhileSwitching={stopAudioWhileSwitching} setStopAudioWhileSwitching={setStopAudioWhileSwitching} setVisemeData={setVisemeData} language={items[selectedItem].label} isMobileDevice={isMobileDevice} />
          </div>

          <div className="butati-bot-container">
            <BotCanvas isTalking={isTalking} isThinking={isThinking} isListening={isListening} visemeData={visemeData} />
          </div>
        </div>
      }

    </>
  );
}

export default App;