const ExteriorGreet = [
  {
    "time": 6,
    "type": "word",
    "start": 0,
    "end": 3,
    "value": "Jai"
  },
  {
    "time": 6,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 113,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 249,
    "type": "word",
    "start": 4,
    "end": 12,
    "value": "Jinendra"
  },
  {
    "time": 249,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 335,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 375,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 442,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 498,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 542,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 578,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 687,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 861,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 1054,
    "type": "word",
    "start": 14,
    "end": 21,
    "value": "Welcome"
  },
  {
    "time": 1054,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 1133,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 1180,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1253,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 1308,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 1354,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 1438,
    "type": "word",
    "start": 22,
    "end": 24,
    "value": "to"
  },
  {
    "time": 1438,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1511,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 1570,
    "type": "word",
    "start": 25,
    "end": 28,
    "value": "the"
  },
  {
    "time": 1570,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 1621,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 1700,
    "type": "word",
    "start": 29,
    "end": 35,
    "value": "Abusha"
  },
  {
    "time": 1700,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 1747,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 1818,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 1895,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 2017,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 2052,
    "type": "word",
    "start": 36,
    "end": 41,
    "value": "mahal"
  },
  {
    "time": 2052,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 2145,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 2201,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 2248,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 2279,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2578,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 2971,
    "type": "word",
    "start": 43,
    "end": 46,
    "value": "I'm"
  },
  {
    "time": 2971,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 3076,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 3178,
    "type": "word",
    "start": 47,
    "end": 53,
    "value": "Araham"
  },
  {
    "time": 3178,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 3264,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 3329,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 3359,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 3457,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 3562,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 3743,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 3930,
    "type": "word",
    "start": 55,
    "end": 56,
    "value": "a"
  },
  {
    "time": 3930,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 4013,
    "type": "word",
    "start": 57,
    "end": 64,
    "value": "virtual"
  },
  {
    "time": 4013,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 4058,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 4181,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 4285,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 4353,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 4395,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4528,
    "type": "word",
    "start": 65,
    "end": 67,
    "value": "AI"
  },
  {
    "time": 4528,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 4652,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 4767,
    "type": "word",
    "start": 68,
    "end": 71,
    "value": "bot"
  },
  {
    "time": 4767,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 4861,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 4968,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5023,
    "type": "word",
    "start": 72,
    "end": 79,
    "value": "created"
  },
  {
    "time": 5023,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 5123,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 5178,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 5283,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 5360,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5405,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5459,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5504,
    "type": "word",
    "start": 80,
    "end": 82,
    "value": "by"
  },
  {
    "time": 5504,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 5552,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 5649,
    "type": "word",
    "start": 83,
    "end": 85,
    "value": "XR"
  },
  {
    "time": 5649,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 5799,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 5879,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 5968,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 6059,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 6113,
    "type": "word",
    "start": 86,
    "end": 92,
    "value": "Vizion"
  },
  {
    "time": 6113,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 6180,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 6265,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 6344,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6614,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 6807,
    "type": "word",
    "start": 94,
    "end": 97,
    "value": "and"
  },
  {
    "time": 6807,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 6852,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6884,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6915,
    "type": "word",
    "start": 98,
    "end": 99,
    "value": "I"
  },
  {
    "time": 6915,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 6989,
    "type": "word",
    "start": 100,
    "end": 105,
    "value": "can't"
  },
  {
    "time": 6989,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 7110,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 7202,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 7239,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 7285,
    "type": "word",
    "start": 106,
    "end": 110,
    "value": "wait"
  },
  {
    "time": 7285,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 7408,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 7485,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 7529,
    "type": "word",
    "start": 111,
    "end": 113,
    "value": "to"
  },
  {
    "time": 7529,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 7575,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 7635,
    "type": "word",
    "start": 114,
    "end": 120,
    "value": "embark"
  },
  {
    "time": 7635,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 7695,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 7766,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 7828,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 7923,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 7986,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 8056,
    "type": "word",
    "start": 121,
    "end": 123,
    "value": "on"
  },
  {
    "time": 8056,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 8112,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 8154,
    "type": "word",
    "start": 124,
    "end": 128,
    "value": "this"
  },
  {
    "time": 8154,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 8189,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8242,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 8353,
    "type": "word",
    "start": 129,
    "end": 136,
    "value": "journey"
  },
  {
    "time": 8353,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 8443,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 8547,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 8603,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8696,
    "type": "word",
    "start": 137,
    "end": 141,
    "value": "with"
  },
  {
    "time": 8696,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 8760,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8807,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 8865,
    "type": "word",
    "start": 142,
    "end": 145,
    "value": "you"
  },
  {
    "time": 8865,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 8952,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 9131,
    "type": "viseme",
    "value": "sil"
  }
]

export default ExteriorGreet;