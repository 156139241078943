import React, { useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";

const Exterior = ({ unloadExterior, setLoadingProgression, setIsLoaded, setUnloadExterior, setReadyToLoad, sendMessageRef, sceneToLoad, isMobileDevice }) => {

  const unityContext = {
    loaderUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.loader.js",
    dataUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.data.br",
    frameworkUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.framework.js.br",
    codeUrl: "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Exterior_12_02/Build/Exterior_12_02.wasm.br",
  };

  const { unityProvider, loadingProgression, isLoaded, unload, UNSAFE__unityInstance, sendMessage } = useUnityContext(unityContext);

  useEffect(() => {
    const unloadAndSetReadyToLoad = async () => {
      if (unloadExterior) {
        await unload();
        if (typeof window !== 'undefined' && window.hasOwnProperty('gc')) {
          window.gc();
        }
        setUnloadExterior(false);
        // console.log("from Exteriro", sceneToLoad.current)
        setReadyToLoad(sceneToLoad.current);
      }
    };

    unloadAndSetReadyToLoad();
  }, [unloadExterior, unload, setReadyToLoad, setUnloadExterior, sceneToLoad]);

  useEffect(() => {
    setLoadingProgression(loadingProgression)
  }, [loadingProgression, setLoadingProgression])


  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(isLoaded)
    }
  }, [isLoaded, setIsLoaded])


  useEffect(() => {
    if (UNSAFE__unityInstance) {
      window.unityInstance = UNSAFE__unityInstance;

      return () => {
        // Clean up the global reference when unmounting or unloading the build
        window.unityInstance = null;
      };
    }
  }, [UNSAFE__unityInstance]);


  useEffect(() => {
    sendMessageRef.current = sendMessage
  }, [sendMessage, sendMessageRef])


  return (
    <Unity unityProvider={unityProvider} />
  );
}

export default Exterior;
